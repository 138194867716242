export class EventBus {
  events = {};

  /**
   * Publish an event
   * @param {string} name  Name of the event
   * @param {*} obj   All other arguments will be sent along with the event
   */
  publish(name: string, ...obj: any): (eventString: string, ...value: any) => void {
    const e = this.events[name];
    if (!e) {
      return;
    }

    e.forEach(fn => fn.apply(this, [...obj]));
  }

  /**
   * Subscribe to an event
   * @param {string} name
   * @param {*} func
   */
  subscribe(name: string, func: (...value: any) => { _index: number; name: string; remove: () => boolean }) {
    if (!this.events[name]) {
      this.events[name] = [];
    }

    const index = this.events[name].push(func) - 1;

    return {
      _index: index,
      name,
      remove: () => this.remove(name, func),
    };
  }

  /**
   * Remove an event by name or name and callback
   * @param {string} name
   * @param {*} func
   */
  remove(name: string, func: any): boolean {
    const e = this.events[name];

    if (e && func === undefined) {
      delete this.events[name];
      return true;
    }

    if (e && func) {
      const index = e.indexOf(func);
      if (index !== -1) {
        e.splice(index, 1);
        if (this.events[name].length === 0) {
          delete this.events[name];
        }
        return true;
      }
    }
    return false;
  }

  /**
   * Remove all events
   */
  removalAll(): void {
    this.events = {};
  }

  /**
   * Get the list of events
   */
  getEvents() {
    return this.events;
  }
}
